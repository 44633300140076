.modal-overlay {
    position:fixed;
    top:0px;
    left:0px;
    background:rgba(0,0,0,.8);
    width:100%;
    height:100%;
    z-index:10;
}
.open-modal {
    position:absolute;
    background:#fff;
    min-width:1024px;
    max-width:100%;
    left:50%;
    transform:translateX(-50%);
    z-index:11;
    padding:15px;
}
