#admin-toolbar {
  width:100%;
  background:black;
  padding-top:10px;
  padding-bottom:10px;
  .inner-wrap {
    margin:0 auto;
    a {
      color:#fff;
      font-size:1.1em;
      font-weight:bold;
      svg {
        margin-right:10px;
      }
    }
    .seperator {
      padding:0 5px;
      color:#fff;
    }
  }
}

.admin-menu {
    height:100%;
    padding-right:1rem;

    ul {
        background:#4d4d4d;
        width:100%;
        list-style:none;
        padding:0px;
        margin:0px;
        li {
            padding:0px;
            font-size:rem-calc(24);
            padding:0px 1em;
            height:3em;
            a {
                color:#fff;
                font-weight:bold;
                line-height:3em;
            }
        }

        li.active {
            background:#878787;
            position:relative;
            &::after {
                content:"";
                position:absolute;
                height:3em;
                width:3em;
                transform:rotate(45deg) scale(-0.70);
                background:#878787;
                right:-1.5em;
                top:0px;
            }
        }
    }
}

.admin-page {
    padding-top:2em;

    .admin-page-header {
        display:flex;
        justify-content: space-between;
        align-items:baseline;
        margin-bottom:2em;
        .actions {
            font-size:1.25rem;
            padding-right:1em;
            button {
                display:flex;
                align-items:center;
                svg {
                    padding-right:.25em;
                }
            }
        }
    }
}

.admin-card, .card {
    background:#fff;
    padding:1em;
    margin-bottom: 1rem;
    border: 1px solid #e6e6e6;
    border-radius: 0;
    background: #fefefe;
    box-shadow: none;
    overflow: hidden;
    color: #0a0a0a;
}
