$pubroot: "";

body {
  background:#f5f8f9;
}
body .main-grid .main-content {
    margin-left:0px;
}

.enviroment-warning {
    background: #ffff46;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

// Util
.outerpadding {
  padding:rem-calc(49) 0.9375rem;
}

.react-icons {
  vertical-align: middle;
}

// Header
header#top-header {
  background:#fff;

  .logo-link {
    display:flex;
    align-items:center;
  }

  .logo {
    width: 174px;
    height: 81px
  }
  .outerpadding {
    max-width:120rem;
    margin:0 auto;
  }

  .avatar-image {
    border-radius:100px;
  }
  .cart-count {
    color: #17469e;
    font-weight: bold;
    line-height: 40px;
    display: inline-block;
    margin-left: -7px;
    position: relative;
    top: 2px;
    font-size: 1.25em;
  }


  .profile {
    margin-left: 10px;    
    cursor:pointer;
    .avatar {
      float:right;
    }
    .name-field {
      float:left;
      height: 100%;
      display:inline-block;
      padding: 10px 0px;
      width: auto;
      .name {
        padding-left: 20px;
        line-height:rem-calc(62);
        font-size:rem-calc(20);
        font-weight:bold;
        height: 100%;
      }
      .chevron-wrap {
        float: right;
        margin: 27px;
        img {
          height:rem-cacl(16);
        }
      }
    }
  }
  .logout {
    padding-left: 20px;
    line-height:rem-calc(62);
    font-size:rem-calc(20);
    font-weight:bold;
    height: 100%;
    color:#000;
  }
 
  .top-bar-right > div {
    display:inline-block;
  }
  > .top-bar {
    display:flex;
    justify-content:space-between;
  }



}


// Expiration Banner
#expiration-notice {
  background:#ea3a49;
  transition: all 0.25s ease;
  overflow:hidden;

.expiration-text{
    position:relative;
    margin:0;
    padding:20px ;
    color:#fff;
    font-size:rem-calc(20);
    font-weight:600;
    padding-right:30px;

    svg {
      margin-right:15px;
      width:26px;
      height:26px;
      position:relative;
      top:5px;
    }

    a.close {
      position:absolute;
      right:0px;
      top:15px;
      color:#fff;

      svg {
        width:32px;
        height:32px;
        position:relative;
        top:5px;
      }
    }
  }

}

// Main
header#top-header,
#admin-toolbar .inner-wrap,
main#main-content {
  padding:0px 65px;
}



// Dashboard 
.yellow-button-large,
.blue-button-large {
  background: #ffcb08;
  color: #363636;
  font-size:rem-calc(20);
  float:right;
  font-weight:600;
  padding-left:40px;
  padding-right:40px;
  margin-top: 8px;
}
.blue-button-large {
  background: #17469e;
  color: #fff;
  margin-left:15px;
}

.title {
  text-align: left;
  font-size: rem-calc(45);
}

.title-container {
  padding: rem-calc(60) 0px;
}

// Credential
.credential, 
.progress-courses, 
.available-courses,
.prerequisite-courses,
.instructions,
.bottom-area {
  span {
    font-size: rem-calc( 25 );
  }
  .horizontal-line {
    height: 1.5px;
    width: 100%;
    background-color: black;    
    position: relative;
    top: 49%;    
  }
  .line-wrap {
    position:relative;
  }
  .horizontal-line-text {
    background:#f5f8f9;
    padding-right:20px;
  }
  .horizontal-line-background {
    position:absolute;
    top:49%;
    border-top:1px solid black;
    z-index:-1;
    width:100%;
  }
  .footer-links a {
    padding-left:2em;
  
  }
}

// Card
.credentialing-card {
  transition: all 0.25s ease;
}

.card {
  position:relative;
  padding: 41px 32px;
}

.label-content-gap {
  margin-bottom: 43px;
}
.card-title {
  font-size: rem-calc( 35 );
  color: #363636;
  font-weight: bold;
}
.card-expiration {  
  color: #ed1b2d;
  font-weight: bold;
  span {
    margin-left: 10px;
    font-size:rem-calc( 18);
  }
}
.card-row {
  margin-bottom: 42px;
}
.card-sub-title {
  font-size:rem-calc( 32);
  color: #363636;
  margin-bottom: 7px;
}
.card-button {
  background-color: #17469e;
  font-size:rem-calc( 20px);
  margin-right:15px;
}
.medal {
  width: 56px;
}

.chevron-button {
  position:absolute;
  right:15px;
  @include breakpoint(small only) {
    &.mobile-center {
      &, &:focus, &:hover {
        position:relative;
        left:50%;
        margin-left:-24px;
      }
    }
  }
}
.progress-courses  {
  .chevron-button {
    @include breakpoint(small only) {
      &.mobile-center {
        &, &:focus, &:hover {
          right:50%;
          left:auto;
        }
      }
    }
  }
}

.chevron-button, .chevron-button:focus, .chevron-button:hover {
  border-radius: 24px;
  outline-style: none;
  background-color: white;
  border-color: #363636;
  margin: auto;
  width: 48px;
  height: 48px;
}

// Progress course
.progress-courses {
  margin-top: 40px;
}
.progress {
  height: 3px;
  width: 100%;
  margin-bottom:5px;
}
.percentage {  
  span {
    font-size:rem-calc( 35);
    color: #363636;
    font-weight: bold;
    display: inline-block;    
  }
}
.percentage-laebel {
  font-size:rem-calc( 14);
}
.chevron-reverse {
  transform: rotate(180deg);  
}
.progress-bar {
  color: #17469e;
}
// Tabs
.progress-tabs {
  margin-top: 46px;

  .tabs-panel {
    padding:2rem;
  }

  .tabs-title {
    & > a {
      font-size:rem-calc( 16);
      color: #363636;
      &:hover, &:focus {
        background-color: #f5f8f9;
        outline: none;
      }
      &:hover {
        background-color: #eceded;
      }
      &.bordered  {
        padding-right:0em;
        strong {
          padding-right:1.5em;
          border-right:1px solid #363636;
        }
      }
    }
  }
  .tabs {
    border: none;  
  }
  .tabs-content, .is-active {
    border: none;
    background-color: #f5f8f9;
    display: block;
  }
  .tab-contents {
    font-size:rem-calc( 35);
    color: #363636;
    font-weight: bold;
  }
  .progress-row {
    line-height:2em;
    .tick {
      height:2em;
      width:auto;
      margin-right:2em;
      @include breakpoint(small only) {
        margin-right:5px;
      }
    }
    button, span {
      font-size:1em;
    }
    button.card-button {
      margin-right:0px;
      padding: .5em 1em;
      margin:0px;
    }
  }
  .locked {
    color:#9e9e9e;
  }
  .req-lock {
    padding-left:15px;
    font-size:1em;
    @include breakpoint(small only) {
      padding-left:5px;
    }
  }


}
.collapsible-group {
  .collapsible-group-inner-wrap {
    transition: all 0.25s ease;
  }
  .chevron-button-inprogress, 
  .chevron-button-inprogress:hover, 
  .chevron-button-inprogress:focus {
    margin:10px 0px 0px;
    padding:0px;
    width:35px;
    height:35px;
  }
}


// cards
.cards {
  margin-top: 50px;
}

// AvailableCourses
.available-courses {
  margin-top: 50px;
}

// PrerequisiteCourses
.prerequisite-courses {
  margin-top: 50px;
}
.prerequisite-item {
  background:#fff;
  border:1px solid #e6e6e6;
  height: 100px;
  padding: 28px 32px;
  margin-bottom: 27px;
}
.prerequisite-item-incomplete {
  background:#ea3949;
  .item-title {
    color:#fff;
  }

}
.tick {  
  width: 45px;
  height: 45px;
  float: left;  
}
.item-title {
  margin-left: 32px;
  font-size:rem-calc( 25);
  color: #363636; 
  float: left;  
}
.list-date {
  float: right;
  font-size:rem-calc( 20px);
}
// bottom area
.bottom-area {
  margin-top: 50px;
  margin-bottom: 60px;
  font-size:rem-calc( 20);
}

//progress item
.inprogress-item {
  border-bottom: 1px solid black;
  padding: 28px 0px;
}
.complete-date {
  float: left;  
  margin: 10px 43px;
  font-size:rem-calc( 20);
  color: #363636;
  @include breakpoint(small only) {
    & {
      float:none;
      clear:both;
    }
  }
}
.inprogress-tab-contents {
  padding: 20px;
}
.inprogress-item-title {
  margin-left: 32px;
  font-weight: bold;
  font-size:rem-calc( 35);
  color: #363636; 
  float: left;  
}
.inprogress-accordion-title {
  font-weight: bold;
  font-size:rem-calc( 35);
  color: #363636; 
  float: left;
  span.title-tag {
    font-size:.5em;
    font-weight:normal;
    padding-left:1.5em;
  
  }
}
.chevron-button-inprogress, 
.chevron-button-inprogress:focus, 
.chevron-button-inprogress:hover {

  border-radius: 24px;
  outline-style: none;
  background-color: #f5f8f9;
  border-color: #363636;
  margin-right: 15px;
  width: 48px;
  height: 48px;  
}
.accordion-title::before, 
.is-active > .accordion-title::before {
  content: '';  
}
.tab-collapse {
  padding: 0px;
  border: 0px;
}
.accordion-title, .accordion-title:hover {
  background-color: #f5f8f9;
  border: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.accordion-item {
  background-color: #f5f8f9;

}
.inProgress-table-header {
  font-size:rem-calc( 20);
  font-weight: bold;
  color: #363636;
  padding-top: rem-calc(30);
  padding-bottom: rem-calc(30);
}
.inProgress-table {
  .small-2 {
    padding: 0px;
  }

}
.table-header-line {
  border-bottom: 1px solid  black;    
}
.tick-table {
  width: 30px;
  float: left;
}
.inprogress-table-tick {
  margin: 0px 10px;
  float: left;
  font-size:rem-calc( 20);
}
.inprogress-table-cell {
  font-size:rem-calc( 20);
}
.top-cells {
  padding: 30px 10px;
}
.table-column{
  padding-bottom: 35px;
  margin-bottom: 14px;  
}
.cell-button {
  width: 143px;
  height: 37px;  
  background-color: #17469e;
}
.bottom-row {
  padding-bottom: 5px;
  border-bottom: 1px solid  black;    
}
.practical {
  font-size:rem-calc( 20);
  margin-top:15px;

  .practical-cell {
    span, p {
      font-size:1em;
    }
  }


}
.pracitcal-button {
  width:100%;
  max-width: 297px;
  margin-top: 21px;
}
:last-child > .accordion-content:last-child {
  border-bottom: none;
}

.schedule-practical {
  padding:rem-calc(75);
  h3 { 
    font-size:rem-calc(24);
    margin-bottom:rem-calc(50);
  }
  p { 
    font-size:rem-calc(24);
    padding-right:rem-calc(50);
  }

  .practical-list {
    .practical-list-item {
      font-size:rem-calc(24);
      padding-bottom:15px;
      .clickable-wrap {
        position:relative;
        cursor:pointer;
        .selection-state-icon {
          position:absolute;
          top:.25em;
          left:-1.5em;
          width:1em;
          height:1em;
          border:1px solid black;
          border-radius:100px;
        }
        div.selected {
          border:1px solid #5bdd9b;
          background-color:#5bdd9b;
          .icon {
            position:absolute;
            top:50%;
            left:50%;
            font-size:.5em;
            margin-left:-.5em;
            margin-top:-.5em;
            color:#fff;
          }
        }
        span.pc-item-title:hover {
          color:#17469e;
          font-weight:bold;
        }
        span.selected {
          font-weight:bold;
          color:#17469e;
        }

        &.not-selectable {
            span.name {
                opacity:.5;
            }
            cursor:not-allowed;
        }

      }
    }

  }
  .schedule-practical-confirmation {
    font-size:rem-calc(24);
    h3 {
      margin-bottom:0px;
    }
    .price-wrap {
      max-width:300px;
      float:right;
      button {
        width:100%;
      }
      .session-price {
        font-size:rem-calc(72);
        font-weight:bold;
        margin-bottom:30px;
      }
    }
  }

  .time-picker {
    width:auto;
    display:inline-block;
  }

}
.question-mark {
  display:none;
  width: 20.5px;
  background-color: #17469e;
  border-radius: 10.25px;
  text-align: center;
  color: white;
}

// Dropdowns
.nav-dropdown-top{ 
  cursor:pointer;
  position:relative;

  .nav-dropdown-wrap {
    display:none;
  }
  &:hover .nav-dropdown-wrap {
    display:block;
  }

  .nav-dropdown-wrap {
    position:absolute;
    padding-top:32px;
    top:49px;
    width:260px;
    z-index:10;

    .nav-indicator {
      background:url($pubroot + "/images/nav-arrow2.jpg") top left no-repeat;
      height:46px;
      width:61px;
      position:absolute;
      top:-8px;
      transform: translateX(-50%) scale(.75);
    }

    .nav-dropdown {
      padding:0px;
      background-color:#FFFFFF;
      font-weight:normal;
      font-size:rem-calc(13);
      border-radius:8px;
      box-sizing:border-box;
      box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, .16);

      h1 {
        font-size:rem-calc( 35);
        font-weight: bold;
        float: left;
        margin-bottom:0px;
      }
      p {
        font-size:rem-calc(16);
      }
    }
  }
}

.top-bar-alignment {
    display:flex;
    align-items:center;
    > div {
        padding:0em 1em;
    }

}

.nav-dropdown {
    ul.menu {
        margin:0px;
        list-style:none;
        li {
            font-size:1.75em;
            padding:.5em;
            text-align:left;
            padding-left:1em;
            border-bottom:1px solid #ccc;
            &:last-child {
                border:none;
            }
        }
    }
}

.notifications .nav-dropdown-wrap {
  left:-50px;
  .nav-indicator {
    left:50px;
  }
}
.shopping-cart .nav-dropdown-wrap {
  left:-110px;
  .nav-indicator {
    left:110px;
  }
}
.profile .nav-dropdown-wrap {
  left:0px;
  .nav-indicator {
    left: 50%;
  }
}

.nav-icon {
  display:inline-block;
  text-align:left;
  width: 35px;
  height: 35px;
  margin: 15px;
}
.notifications {  
  position:relative;
  background-color: none;
}
.shopping-cart {

  .nav-icon {
    width: 40px;
    height: 40px;
    margin:10px;
  }

  .cart-footer {
    margin-top:35px;
    .subtotal {
      font-size:rem-calc(16);
      color:#919191;
      font-weight:bold;
      line-height:.75em;
      span {
        line-height:1em;
        font-size:rem-calc(35);
        color:#000;
        font-weight:bold;
      }
    }
  }
}

.notifications h3 {margin:12px 0;}

.settings {
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 18px;
}
.notification-title-container {
  border-bottom: 1px solid #9a9a9a;
  padding: 20px 0px;
  &:first-child {
    border-bottom:1px solid black;
  }
}
.notification-text {
  font-size:rem-calc( 16px);
  width: 338px;
  float: left;

}
.red-dot {
  width: 15px;
  height: 15px;  
  border-radius: 7.5px;    
  background-color: red;
  margin: 10px;
  float: left;
}
.notification-text-size {
  font-size:rem-calc( 16px);
  width: 100%;
}
.profile {
  .nav-dropdown-wrap .nav-dropdown {
    .header {
      h2 {
        font-size:rem-calc(35);
        margin-bottom:10px;
      }
      h3 {
        font-size:rem-calc(16);
        font-weight:normal;
      }
    }
    h1 {
      font-size:rem-calc(20);
  }
    .expanded-bio {
      padding:20px 0px;
      border-bottom:1px solid black;
    }
    .links {
      padding:20px 0px;
      a {
        color:#363636;
        text-decoration:underline;
        font-size:rem-calc(18);
        &.button {
          color:#fff;
          text-decoration:none;
          float:right;
        }
      }
      
    }
  }
}

.disabled-overlay {
  background:#f5f8f9;
  opacity:.35;
  position:relative;
  .disable-clicks {
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    z-index:10;
  }
}
.prereq-notice {
  font-size:rem-calc(24);
  margin-top:25px;
}

// Mobile Menu
.bm-menu-wrap {
  background:white;
  padding:0px !important;
  a {
    display:block;
    width:100%;
    padding:10px 20px;
    font-size:rem-calc(24);
    color:#363636;
    border-bottom:1px solid #363636;
  }
}
// Disable default button
.bm-burger-button {
  display:none;
}
.mobile-menu {
  .bm-menu {
    a {
      font-size:rem-calc(30);
    }
  }
  #hamburger-menu {
    color:#363636;
    line-height:100px;
    float:right;

  }
}



/* Responsive Overrides */
@media screen and (max-width: 1600px) {
  html {
    font-size:15px;
  }
  .outerpadding {
    padding-left:15px;
    padding-right:15px;
  }

  header#top-header,
  #admin-toolbar .inner-wrap,
  main#main-content {
    padding:0px 15px;
  }
}
@media screen and (max-width: 1300px) {
  html {
    font-size:13px;
  }
}
@media screen and (max-width: 1024px) {
  html {
    font-size:11px;
  }
  .outerpadding {
    padding:15px;
  }
  main#main-content {
    padding:0px 15px;
  }

}
@include breakpoint(small only) {
   .yellow-button-large {
      float:none;
   }
   header#top-header > .top-bar {
    display:block;
   }


}

// Login Form
.login-form {
  min-height:100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
   align-items: center;

  .login-form-box {
    background:#fff;
    margin:0 auto;
    padding:15px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);

    .logo {
      max-width:300px;
    }

    h1 {
      margin:15px;
    }

    input {
      font-size:18px;
    }
    
  }
  
}
.breadcrumb {
  padding:30px 0px 30px;
  a {
    color:#363636;
    font-size:rem-calc(20);
    font-weight:bold;
  }
}

// Written Tests
.written-test {
  width: 100%;
  .lesson-title {
    font-size:rem-calc(78);
    @include breakpoint(small only) {
      font-size:rem-calc(32);
    }
  }
  .lesson-content {
    padding:rem-calc(50) rem-calc(70);
    background:#f5f8f9;
    @include breakpoint(small only) {
      padding:0;
      background:#fff;
    }
    h2 {
      font-size:rem-calc(35);
      margin-bottom:rem-calc(45);
    }
    h3 {
      font-size:rem-calc(25);
    }
    .breakdown {
      list-style: none;
      margin-left:0px;
      li {
        position:relative;
        &::before {
          position:absolute;
          top:50%;
          margin-top:-4px;
          left:0px;
          width:9px;
          height:9px;
          background:#17469e;
          content:"";
          border-radius:100px;
        }
        padding-left:34px;
        font-size:rem-calc(20);
        line-height:rem-calc(32);
      }
    }
    .lesson-overview {
      margin-bottom:rem-calc(45);
    }
    .quiz-question {
      margin-bottom:25px;
      .answer-group {
        margin-top:25px;

      }
    }
    .answer {
      font-size:rem-calc(18);
    }
    .next-steps {
      font-size:rem-calc(22);
    }
    .grade {
      font-size:rem-calc(75);
    }
    .success {
      color:green;
    }
    .danger {
      color: red;
    }
  }

}

// Modals
.modal-overlay {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height: 100vh;
  background:#000;
  opacity:.5;
}
.modal-content {
  position: absolute;
  top:35px;
  left:0px;
  width:90%;
  margin:0 5%;
  min-height:200px;
  background:#fff;
  padding:35px;
  text-align:center;
}
.close-modal {
  color:#363636;
  position:absolute;
  top:15px;
  right:15px;
}

#add-to-cart-modal {

  font-size:rem-calc(35);

  h1 {
    font-size:rem-calc(45);
    margin-bottom:25px;
  }


  .item-row {
    max-width:800px;
    margin:0 auto;
    clear:both;
    dt { float: left; width: 80%; overflow: hidden; white-space: nowrap }
    dd { float: left; overflow: hidden }
    dt span:after { content: " .................................................................................." }

    @include breakpoint(small only) {
      dt { float: none; width: 100%; white-space: wrap;overflow:visible; }
      dt span:after { content: "" }
      dd { float: none;width:100%; overflow: hidden;text-align:right; }
      dd span:before { content: ".........................." }

    }
  }
  .button-group {
    padding-top:35px;
    button {
      margin:10px;
    }
  }

}
#welcome-modal {
  background:#f5f8f9;

  .close-modal {
    font-size:rem-calc(35);
  }

  .inner-content {
    padding:rem-calc(75);

    h1 {
      margin-bottom:rem-calc(50);
    }
    p {
      font-size:rem-calc(24);
      margin-bottom:rem-calc(50);
    }
    .reminder {
      margin:rem-calc(50);
      text-align:center;
      text-decoration:underline;
      color:#363636;
      font-size:rem-calc(20);
    }
  }
}

#expiration-modal {
  .close-modal {
    font-size:rem-calc(35);
  }

  .inner-content {
    padding:rem-calc(75);

    h1 {
      margin-bottom:rem-calc(50);
      color:#ea3a49;
      font-size:rem-calc(78);
    }
    p {
      font-size:rem-calc(24);
      margin-bottom:rem-calc(50);
    }
    .expired-credentials {
      font-size:rem-calc(24);
      .buttons {
        padding-left:rem-calc(50);
        display:inline-block;
        button {
          margin:0 15px;
          font-size:rem-calc(24);
          padding:.5em 2em;
        }
      }
      span {
        font-weight:normal;
        padding:0px 20px;
      }
    }
  }
}

// Checkout
.checkout {
  width:100%;
  padding:50px;
  .items {
    width:100%;
  }
  .error-message {
    padding: 25px;
    background: #ffd2d2;
    border: 1px solid red;
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 15px;
  }
  input.error, select.error {
    border:1px solid red;
  }

  .checkout-footer {
    clear:both;
    text-align:right;
    .pricing {
      display:inline-block;
      text-align:left;
      padding-right:25px;
      .subtotal {
        font-weight:bold;
        span {
          font-size:rem-calc(34);
        }
      }
    }
    
  }

}
.confirmation {
  h2 {
    font-size:rem-calc(24);
    font-weight:normal;
  }
  .confirmation-footer {
    .button-group {
      padding-top:30px;
      float:right;
      max-width:300px;
      button {
        width:100%;
        margin-bottom:20px;
        font-size:rem-calc(20);
      }
    }
  }
}

.commerce-item-list {
  font-size:rem-calc(24);
  .course-list {
    padding:40px 0px 40px 0px;
    .item-row {
      margin:0 auto;
      clear:both;
      line-height:1.5em;
      dt { float: left; width:90%;overflow: hidden; white-space: nowrap }
      dd { float: left; overflow: hidden }
      dt span:after { content: " .........................................................................................................................................................................................................................................................................................................................................................................................................................................." }
    }
    .item-row-details {
      font-size:rem-calc(20);
    }
  }
}

// Print
.print-only {
  display:none;
}


@keyframes spinner {
  to {transform: rotate(360deg);}
}
.fa-spin {
  animation: spinner .8s linear infinite;
}

// History
.credential-history {
  .credential-row {
    padding:15px 0px;
    .credential-name {
      font-size:rem-calc(24);
      img {
        width:1em;
        height:1em;
        margin-top:5px;
      }
    }
    .credential-req {
        padding-left:2em;
    }
  }
}

.all-credentialing-history {
  background-color: #f5f8f9;
  padding:1em 3em;
  .credential-row {
    padding:5px 0px;
  }
  .expired {
    opacity:.5;
  }
  .expired-line {
    text-align:center;
    font-size:rem-calc(24);
    position:relative;
    &::before {
      content:" ";
      height:1px;
      width:100%;
      background:black;
      position:absolute;
      top:50%;
      left:0px;
      margin-top:-1px;
    }
    span {
      display:inline-block;
      padding:1em;
      background-color: #f5f8f9;
      position:relative;
      text-transform:uppercase;
      font-weight:bold;
    }

  }
}
.data-table {
  font-size:rem-calc(18);
  header {
    font-size:rem-calc(22);
    font-weight:bold;
    padding-bottom:1em;
    margin-bottom:1em;
    border-bottom:1px solid black;
  }
}
button a, button:hover a {
  color:#fff;
}

.practical-test {
  .collapsible-group {
    width:100%;
  }
  .practical-grade {
    .success {
      color:green;
    }
    .danger {
      color: red;
    }
  }
}

.date-unavailable {
  opacity:.4;
}

.wp-page {
  margin-top:25px;
}

.login-links {
  a, div, button {
    display:block;
    width:100%;
    text-align:center;
  }
}


.instructions {
  margin-bottom:50px;
  .expand {
    width:100%;
    text-align:center;
    display:block;
    position:relative;
  }
  .instruction-minimized {
    overflow:hidden;
    max-height:150px;
    margin-bottom:-25px;
    position:relative;
    &::after {
      content: "";
      position:absolute;
      background: rgb(255,255,255);
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      bottom:0px;
      left:0px;
      width:100%;
      height:125px;

    }
  }
}


// Worlds
.worlds-notice-wrap {
  max-width:1920px;
  padding:0px 15px;
  margin:-25px auto 50px;

  .worlds-notice {
    width:100%;
    padding:20px;
    background:url($pubroot + "/images/space-bg.jpg") top left no-repeat;
    background: linear-gradient(90deg, rgba(0,25,50,.75) 11%, rgba(255,255,255,0) 100%), url($pubroot + "/images/space-bg.jpg") top right no-repeat;

    display:flex;
    align-content:center;
    align-items:center;
    div {
      flex:.25 1;
      min-width:150px;
      max-width:175px;
      img {
        height:auto;
        padding-left:15px;
      }
    }
    a {
      flex:1;
      text-align:center;
      padding:20px;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      color:#fff;
      font-size:2em;
      text-decoration:underline;
    }
  }
}
.study-at-home-course {
  border-bottom:1px solid #d7d7d7;
  padding-bottom:15px;
  padding-top:15px;
  &:nth-child(even) {
    background:#f0efef;
  }
  &:last-child {
    border-bottom:0px;
    margin-bottom:0px;
  }
  .tick {
    margin-left:10px;
    width:2.75em;
    height:2.75em;
    &.gray {
      opacity:.5;
    }
  }
  .item-title {
    margin-left:20px;
  }
  button {
    margin-left:15px;
    float:right;
  }
}

// Vimeo Embeds
.wp-block-embed.is-type-video {
  width:100%;
  background:#000;
  max-height:576px;
  overflow: hidden;
  .wp-block-embed__wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    width:100%;
    margin:0 auto;
    max-width:1024px;
    iframe {
      position: absolute;
      max-height:576px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}

form.hook-form {
    label {
        font-weight:bold;
        font-size:1em;
    }
    .error {
        margin:-1em 0px 1em;
        padding:0px;
        font-style:italic;
        color:red;
    }
}
